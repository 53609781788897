/* Header styles */
.header {
  background: linear-gradient(90deg, #4b6cb7, #182848); /* Gradient màu xanh dương → tím */
  padding: 1rem;
  text-align: center;
  color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.header, .footer {
  width: 640px; /* Khớp với kích thước canvas */
  margin: 0 auto; /* Căn giữa trên trang */
  padding: 20px 0; /* Tăng khoảng cách trên và dưới */
  text-align: center; /* Nội dung căn giữa */
  background-color: #1a202c; /* Màu nền tối hơn */
}

canvas {
  width: 640px; /* Chiều ngang canvas */
  height: auto; /* Tự động tỷ lệ chiều cao */
  display: block; /* Loại bỏ khoảng trống xung quanh */
  margin: 0 auto; /* Căn giữa canvas */
}



.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
}

/* Footer styles */
.footer {
  background: linear-gradient(90deg, #1e1e1e, #3c3c3c); /* Gradient màu tối */
  color: #ccc;
  text-align: center;
  padding: 1rem 0;
  font-size: 0.9rem;
  margin-top: 2rem;
}

.footer .links a {
  color: #f0ad4e; /* Màu vàng nhẹ */
  text-decoration: none;
  margin: 0 0.5rem;
}

.footer .links a:hover {
  color: #ffc107; /* Vàng sáng khi hover */
}

/* Main content placeholder (optional) */
.main-content {
  padding: 1rem;
  min-height: 500px;
}
